import ReactGA4 from 'react-ga4';
import ReactFacebookPixel from 'react-facebook-pixel';
import RedditPixel from 'react-reddit-pixel';
import axios from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid';

import LinkedInTag from 'react-linkedin-insight';

const eventTarget = new EventTarget();

const eventServiceToken = {
	token: null,
};

const suid = uuidv4();

const subscribe = (callback) => {
	if (eventServiceToken.token) {
		callback(eventServiceToken);
		return () => {};
	}

	const handler = () => {
		if (eventServiceToken.token) {
			callback(eventServiceToken);
			eventTarget.removeEventListener('ready', handler);
		}
	};
	eventTarget.addEventListener('ready', handler);

	return () => {
		eventTarget.removeEventListener('ready', handler);
	};
};

eventServiceToken.subscribe = subscribe;

const sendBeacon = (url, body = {}) => {
	try {
		const reqHeaders = { type: 'application/json' };
		const blob = new Blob(
			[JSON.stringify({ ...body, Authorization: eventServiceToken.token })],
			reqHeaders
		);
		navigator.sendBeacon(url, blob);
	} catch (error) {
		axios.post(url, body, {
			headers: { Authorization: eventServiceToken.token },
		});
	}
};

export { eventServiceToken };

export function setEventServiceToken(token) {
	eventServiceToken.token = token;
	eventTarget.dispatchEvent(new Event('ready'));
}

export async function initEventsManager() {
	// if(true){
	// eslint-disable-next-line
	if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
		const generalData = {};
		const now = new Date();

		generalData.landing_page_pathname = window.location.pathname;
		generalData.landing_page_search = window.location.search;

		generalData.user_agent = navigator.userAgent;
		if (
			/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
				navigator.userAgent
			)
		) {
			generalData.device = 'mobile';
		} else {
			generalData.device = 'desktop';
		}

		generalData.referrer = document.referrer;
		generalData.creation_time = now.toUTCString();

		// duid => devise unique id
		generalData.duid = localStorage.getItem('duid');
		if (generalData.duid === null || generalData.duid === '') {
			generalData.duid = uuidv4();
			localStorage.setItem('duid', generalData.duid);
		}
		// suid => session unique id
		generalData.suid = suid;
		sendBeacon('/api/session', generalData);
	}
}

export async function sendPageview() {
	// if (true) {
	// eslint-disable-next-line
	if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
		// eslint-disable-next-line
		ReactFacebookPixel.pageView();
		RedditPixel.pageVisit();
		const now = new Date();
		const event = {
			category_type: 'General',
			activity_type: 'Page View',
			url: window.location.pathname,
			creation_time: now.toUTCString(),
			log_data: {},
			url_pathname: window.location.pathname,
			url_search: window.location.search,
			suid,
		};

		sendBeacon('/api/log', { event, source: 'pageview' });
		// TBD: add linkedin pageview event just like the google and facebook
	}
}

/**
 *
 * @param {{
 * gaName: string;
 * gaParams: Object;
 * fbIsCustom;
 * fbEvent;
 * fbParams;
 * gtag;
 * rdtEvent;
 * linkedInConversionId;
 * }} data
 */

export async function sendEvent(data) {
	const {
		gaName,
		gaParams,
		fbIsCustom,
		fbEvent,
		fbParams,
		gtag,
		rdtEvent,
		linkedInConversionId,
	} = data;

	if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
		if (gaName) {
			const now = new Date();
			const event = {
				activity_type: gaName,
				url: window.location.pathname,
				creation_time: now.toUTCString(),
				log_data: gaParams || {},
				url_pathname: window.location.pathname,
				url_search: window.location.search,
				suid,
			};
			ReactGA4.event(gaName, gaParams);

			sendBeacon('/api/log', { event, source: 'sendEvent' });
		}
		if (gtag) {
			ReactGA4.event(gtag.event_type, gtag.event_params);
		}
		if (rdtEvent) {
			RedditPixel.track(rdtEvent);
		}
		if (fbEvent) {
			if (fbParams) {
				if (fbIsCustom) {
					ReactFacebookPixel.trackCustom(fbEvent, fbParams);
				} else {
					ReactFacebookPixel.track(fbEvent, fbParams);
				}
			} else if (fbIsCustom) {
				ReactFacebookPixel.trackCustom(fbEvent);
			} else {
				ReactFacebookPixel.track(fbEvent);
			}
		}
		if (linkedInConversionId) {
			LinkedInTag.track(linkedInConversionId);
		}
	} else if (gaName) {
		// log event being sent for dev purpose
		const now = new Date();
		const event = {
			activity_type: gaName,
			url: window.location.pathname,
			creation_time: now.toUTCString(),
			log_data: gaParams || {},
			url_pathname: window.location.pathname,
			url_search: window.location.search,
			suid,
		};
		console.log(event);
	}
	// TBD: add linkedin event just like the google and facebook conversion
}

export async function sendClickEvent(gaParams) {
	sendEvent({
		gaName: 'raw_click',
		gaParams,
	});
}
