import React from 'react';
import Loader from 'react-loader-spinner';

/**
 * @function Spinner
 * @param {{type: 'Bars'|'Oval', className: 'element-loader'|'infinite-loader', isLoading: boolean, nonFixed: boolean}} props
 * @param {string} prop.type - type options: Bars, Oval. Bars by default.
 * @param {string} prop.className - className options : element-loader, infinite-loader. Empty by default.
 * @returns {JSX.Element}
 */

const Spinner = (props) => {
	const {
		type = 'Bars',
		className,
		isLoading = false,
		height = 100,
		nonFixed,
		color = '#1652F0',
	} = props;
	return (
		<div style={isLoading ? { height } : {}} className={className || ''}>
			<Loader
				visible={isLoading}
				type={type}
				color={color}
				height={height}
				width={height}
				className={`preloader ${nonFixed ? 'non-fixed' : ''}`}
			/>
		</div>
	);
};

export default Spinner;
